<template>
  <v-container class="down-top-padding" fluid>
    <base-breadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"></base-breadcrumb>
    <base-card heading="Relatórios de Escolas">
      <v-container class="my-2">
        <v-row>
          <v-spacer/>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              background-color="transparent"
              clearable
              dense
              hide-details
              label="Pesquisar escolas"
              outlined
              @keydown="e => keyObserver(e)"
              @click:append="getClassData()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <datatable-list-external-link
        v-if="schoolFetch"
        :displayActionItems="displayActionItems"
        :headers="headers"
        :items="items"
        :loading="!schoolFetch"
        :pagination="pagination"
        :showSelect="showSelect"
        :sortBy="sortBy"
        :tableUser="true"
        :text="text"
        @update="pagination = { ...pagination, ...$event}"
      ></datatable-list-external-link>
      <v-skeleton-loader
        v-for="i in 5"
        v-else
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    organizationId: '',
    page: {
      title: 'Relatórios'
    },
    schoolFetch: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Relatórios',
        disabled: false,
        to: '/reports/schools'
      }
    ],
    text: {
      searchLabel: 'Pesquisar Escolas',
      emptyLabel: 'Nenhuma escola encontrada'
    },
    headers: [
      {
        text: 'Nome da Escola',
        value: 'name',
        align: 'start'
      },
      { text: 'Cidade', value: 'city', align: 'start' },
      { text: '', value: 'url', align: 'center' }
    ],
    sortBy: '',
    search: '',
    _timerId: null,
    showSelect: false,
    displayActionItems: false,
    items: [],
    totalNumberOfItems: 0,
    domains: [],
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  watch: {
    async 'pagination.itemsPerPage' (oldValue, newValue) {
      if (oldValue !== newValue) {
        await this.getClassData(this.id)
      }
    },
    // search (newV, oldV) {
    //   if (newV === oldV) return
    //   clearTimeout(this._timerId)
    //   this._timerId = setTimeout(async () => {
    //     this.getClassData()
    //   }, 1500)
    // },
    async 'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        await this.getClassData(this.id)
      }
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.organizationId = id
    if (id) {
      await this.getClassData(id)
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
    // this.getClassData()
  },
  methods: {
    buildQuery () {
      const pagination = `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}${this.search ? '&search=' + this.search : ''}`
      return pagination
    },
    formatSchool (schools) {
      const arr = []
      if (!schools.items.length) return arr
      schools.items.forEach((value, index) => {
        if (value) {
          const name = this.formatUrlParams(value.data.name)
          const obj = {
            ...value.data,
            city: value.data.address.city,
            url: `schools/view/${name}`,
            id: value.metadata.id
          }
          arr.push(obj)
        }
      })
      return arr
    },
    formatUrlParams (name) {
      const params = {
        'ds83.school_name_param': `${name}`,
        'ds63.school_name_param2': `${name}`,
        'ds79.school_name_param3': `${name}`,
        'ds94.school_name_param4': `${name}`
      }
      const paramsAsString = JSON.stringify(params)
      return encodeURIComponent(paramsAsString)
    },
    async getClassData () {
      if (this.isSearching) return

      this.schoolFetch = false
      this.isSearching = true
      try {
        const classResponse = await this.$axios.get(
          `/organizations/${this.organizationId}/schools${this.buildQuery()}`
        )

        if (!classResponse.data) {
          throw classResponse
        }

        this.items = this.formatSchool(classResponse.data)
        const pagination = classResponse.data.pagination
        this.pagination = {
          ...this.pagination,
          total: pagination.total,
          page: pagination.current_page,
          itemsPerPage: pagination.per_page
        }
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
        this.schoolFetch = true
        this.isSearching = false
      }
    },
    keyObserver (e) {
      if (e.which === 13) {
        this.getClassData()
      }
    }
    // handleClick (value) {
    //   this.$router.push({ path: '/class' })
    // }
  }
}
</script>
